.clearfix:after {
  content: '';
  display: block;
  overflow: hidden;
  visibility: hidden;
  height: 0;
  clear: both;
}
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  background-color: #ddd;
}
::-webkit-scrollbar-thumb {
  background-color: #757575;
}
.common-scroll-box {
  overflow: hidden;
}
.common-scroll-box > div {
  width: 100%;
  height: 100%;
  padding-right: 18px;
}
.dot {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}
.dot li {
  display: inline-block;
  margin: 0 5px;
  width: 32px;
  height: 6px;
  background-color: #fff;
  border-radius: 3px;
  cursor: pointer;
}
.dot li button {
  display: none;
}
.dot li.slick-active,
.dot li:hover {
  background: #2c7ffa;
  background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#69b2fd), to(#2c7ffa));
}
.box-shadow {
  box-shadow: 0 0 10px #b7c5d9;
  border-radius: 6px;
}
.common-header-shadow {
  box-shadow: 0 0 10px #b7c5d9;
}
.common-pagination {
  color: #999;
  text-align: center;
  font-size: 12px;
}
.common-pagination a {
  display: inline-block;
  margin: 0 4px;
  padding: 0 10px;
  height: 22px;
  border: 1px solid #999;
  line-height: 24px;
  text-align: center;
  color: #999;
  border-radius: 4px;
}
.common-pagination a.disable {
  color: #ddd;
  border-color: #ddd;
  cursor: no-drop;
}
.common-pagination a.active {
  cursor: default;
}
.common-pagination a:not(.disable):not(.active):hover {
  color: #fff;
}
.common-pagination span {
  font-weight: bold;
  margin: 0 2px;
}
.common-pagination p {
  display: inline-block;
}
.common-pagination input {
  margin: 0 8px;
  width: 36px;
  height: 22px;
  border: 1px solid #999;
  border-radius: 4px;
  text-align: center;
  font-size: inherit;
}
.common-pagination button {
  margin-left: 10px;
  width: 46px;
  height: 24px;
  line-height: 24px;
  color: #fff;
  border-radius: 4px;
  vertical-align: middle;
  font-size: inherit;
  cursor: pointer;
}
.common-pagination button:active {
  line-height: 26px;
}
.common-header {
  overflow: hidden;
  padding: 22px 0;
  height: 46px;
}
.common-header .common-header-box {
  margin: 0 auto;
  width: 1000px;
}
.common-header .logo {
  float: left;
  width: 148px;
  height: 52px;
}
.common-header .nav {
  float: right;
}
.common-header .nav li {
  float: left;
}
.common-header .nav li a {
  margin-left: 60px;
  line-height: 46px;
  font-size: 16px;
  color: #333333;
}
.common-header .nav li a.active,
.common-header .nav li a:hover {
  background: -webkit-linear-gradient(#39a6fa, #1058c4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #1767df;
}
.common-footer .contact-link {
  background-color: #dedee7;
  padding: 68px 0 50px;
  height: 83px;
}
.common-footer .contact-link-box {
  margin: 0 auto;
  width: 1000px;
}
.common-footer .contact-link .ft-logo,
.common-footer .contact-link .contact,
.common-footer .contact-link .social {
  float: left;
}
.common-footer .contact-link .ft-logo {
  display: block;
  width: 173px;
  height: 83px;
  background: url(../img/home_bottom-logo.png) left top no-repeat;
}
.common-footer .contact-link .ft-logo:hover {
  background-image: url(../img/home_Light.bottom-logo.png);
}
.common-footer .contact-link .contact {
  width: 463px;
  border: 1px solid #666;
  border-width: 0 1px;
}
.common-footer .contact-link .contact dl {
  float: left;
  margin-left: 60px;
  height: 70px;
  font-size: 18px;
  color: #666;
}
.common-footer .contact-link .contact dl dt {
  margin-bottom: 30px;
}
.common-footer .contact-link .contact a:hover {
  text-decoration: underline;
}
.common-footer .contact-link .social {
  float: right;
}
.common-footer .contact-link .social a {
  display: inline-block;
  margin-top: 8px;
  margin-left: 65px;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
}
.common-footer .contact-link .social a:hover {
  background-repeat: no-repeat;
}
.common-footer .contact-link .social a.wechat {
  margin-left: 0;
  width: 69px;
  height: 60px;
  background-image: url(../img/home_Black-wechat.png);
}
.common-footer .contact-link .social a.wechat:hover {
  background-image: url(../img/home_Light-wechat.png);
}
.common-footer .contact-link .social a.qq {
  width: 52px;
  height: 60px;
  background-image: url(../img/home_Black-qq.png);
}
.common-footer .contact-link .social a.qq:hover {
  background-image: url(../img/home_Light-qq.png);
}
.common-footer .contact-link .social a.weibo {
  width: 71px;
  height: 60px;
  background-image: url(../img/home_Black-sina.png);
}
.common-footer .contact-link .social a.weibo:hover {
  background-image: url(../img/home_Light-sina.png);
}
.common-footer .copyright {
  background-color: #2d3447;
  height: 25px;
  padding: 22px 0;
  color: #ddd;
  font-size: 14px;
  text-align: center;
}
.common-footer .copyright .copy-text {
  display: inline-block;
}
.common-footer .copyright .copy-text span {
  margin-left: 40px;
  line-height: 25px;
}
.common-footer .copyright .certificate {
  margin-left: 78px;
  display: inline-block;
  vertical-align: middle;
}
.common-footer .copyright .certificate a {
  display: inline-block;
  vertical-align: middle;
  margin: 0 12px;
  width: 70px;
  height: 25px;
}
.common-footer .copyright .certificate a.logo-360 {
  background-image: url(../img/home_bottom-360.png);
}
.common-footer .copyright .certificate a.logo-norton {
  background-image: url(../img/home_bottom-norton.png);
}
.modal-alert {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 111;
  background-color: rgba(0, 0, 0, 0.8);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
}
.modal-alert img {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  display: inline;
  width: 380px;
  height: 400px;
}
